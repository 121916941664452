.lib--dialog {
  .fixed;
  top: 0;
  bottom: 0;
  z-index: 1003;
  .text-center;
  .hide;
  left: -17px;
  right: -17px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @media @768 {
    padding: 0 5%;
  }
  .elm_dialog_background {
    .fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background-color: fade(@main-color,60%);
    @media @1024 {
      cursor: pointer;
    }
  }
  &:before {
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    content: normal;
  }
}

.part_dialog {
  animation: 0.3s ease 0s backwards 1 dialogFadeIn;
  .relative;
  .text-left;
  vertical-align: middle;
  border-radius: 0;
  z-index: 1003;
  margin: @font-size-16*4 auto;
  width: 100%;
  max-width: 60vw;
  &.mod--align-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
    min-height: calc(~"100% - 8em");
  }
  .wrp_dialog {
    position: relative;
    background-color: fade(@background,80%);
    padding: @font-size-32 @font-size-16 @font-size-16;
    pointer-events: auto;
    @media @480 {
      padding: @font-size-16 @font-size-24;
    }
  }
  @media @480 {
    width: calc(~"100% - 34px");
    .wrp_dialog_body {
      .text-center;
    }
  }
  &.mod--size-small {
    max-width: @font-size-100*5;
  }
  &.mod--size-normal {
    max-width: none;
  }
  &.mod--size-big {
    max-width: 80em;
  }
  @media @480 {
    min-width: 0;
    max-width: 100%;
  }
  .part_ui_heading {
    span {
      font-size: @font-size-24;
    }
  }
  .elm_dialog_close {
    .absolute;
    color: @main-color-7;
    transition: @transition-color;
    right: @font-size-16;
    top: @font-size-16/2;
    z-index: 1001;
    &:hover {
      color: @main-color-4;
    }
  }

  @keyframes dialogFadeIn {
    0% {
      opacity:0;
      transform:translateY(-10px)
    }
    to {
      opacity:1;
      transform:translateY(0)
    }
  }

  .lib--dialog & {
    display: inline-block;
  }

  button {
    appearance: none;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    color: inherit;
    font-size: 1em;
  }

  .elm_video {
    position: relative;
    width: 100%;
    &:before {
      content: "";
      display: block;
      padding-bottom: 56.25%;
    }
    iframe {
      .absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}
