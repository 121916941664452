.hide {
  display: none;
}

.block {
  display: block;
}

.hidden {
  display: none !important;
  visibility: hidden;
}
.visible {
  display: block !important;
  visibility: visible;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

.fl {
  float: left;
}

.fr {
  float: right;
}
.ffl {
  float: left !important;
}
.ffr {
  float: right !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.table-center {
  display: table;
  margin: 0 auto;
}
.block-center {
  display: block;
  margin: 0 auto;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.absolute-center {
  display: table;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 30%;
}

.image-center {
  position: absolute;
  margin: auto;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
}

.image-background(@background) {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: @background;
    transition: @transition-background;
  }
}
.image-background {
  .image-background(rgba(0,0,0,0.5));
}

.col-1 {
  width: 8.33333%;
}
.col-2 {
  width: 16.6667%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.3333%;
}
.col-5 {
  width: 41.6667%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.3333%;
}
.col-8 {
  width: 66.6667%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.3333%;
}
.col-11 {
  width: 91.6667%;
}
.col-12 {
  width: 100%;
}
