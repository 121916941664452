/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
@flag-cz-name: flag-cz;
@flag-cz-x: 0px;
@flag-cz-y: 0px;
@flag-cz-offset-x: 0px;
@flag-cz-offset-y: 0px;
@flag-cz-width: 20px;
@flag-cz-height: 12px;
@flag-cz-total-width: 41px;
@flag-cz-total-height: 38px;
@flag-cz-image: '../img/sprite.png';
@flag-cz: 0px 0px 0px 0px 20px 12px 41px 38px '../img/sprite.png' flag-cz;
@flag-de-name: flag-de;
@flag-de-x: 21px;
@flag-de-y: 0px;
@flag-de-offset-x: -21px;
@flag-de-offset-y: 0px;
@flag-de-width: 20px;
@flag-de-height: 12px;
@flag-de-total-width: 41px;
@flag-de-total-height: 38px;
@flag-de-image: '../img/sprite.png';
@flag-de: 21px 0px -21px 0px 20px 12px 41px 38px '../img/sprite.png' flag-de;
@flag-en-name: flag-en;
@flag-en-x: 0px;
@flag-en-y: 13px;
@flag-en-offset-x: 0px;
@flag-en-offset-y: -13px;
@flag-en-width: 20px;
@flag-en-height: 12px;
@flag-en-total-width: 41px;
@flag-en-total-height: 38px;
@flag-en-image: '../img/sprite.png';
@flag-en: 0px 13px 0px -13px 20px 12px 41px 38px '../img/sprite.png' flag-en;
@flag-es-name: flag-es;
@flag-es-x: 21px;
@flag-es-y: 13px;
@flag-es-offset-x: -21px;
@flag-es-offset-y: -13px;
@flag-es-width: 20px;
@flag-es-height: 12px;
@flag-es-total-width: 41px;
@flag-es-total-height: 38px;
@flag-es-image: '../img/sprite.png';
@flag-es: 21px 13px -21px -13px 20px 12px 41px 38px '../img/sprite.png' flag-es;
@flag-fr-name: flag-fr;
@flag-fr-x: 0px;
@flag-fr-y: 26px;
@flag-fr-offset-x: 0px;
@flag-fr-offset-y: -26px;
@flag-fr-width: 20px;
@flag-fr-height: 12px;
@flag-fr-total-width: 41px;
@flag-fr-total-height: 38px;
@flag-fr-image: '../img/sprite.png';
@flag-fr: 0px 26px 0px -26px 20px 12px 41px 38px '../img/sprite.png' flag-fr;
@flag-ru-name: flag-ru;
@flag-ru-x: 21px;
@flag-ru-y: 26px;
@flag-ru-offset-x: -21px;
@flag-ru-offset-y: -26px;
@flag-ru-width: 20px;
@flag-ru-height: 12px;
@flag-ru-total-width: 41px;
@flag-ru-total-height: 38px;
@flag-ru-image: '../img/sprite.png';
@flag-ru: 21px 26px -21px -26px 20px 12px 41px 38px '../img/sprite.png' flag-ru;
@spritesheet-width: 41px;
@spritesheet-height: 38px;
@spritesheet-image: '../img/sprite.png';
@spritesheet-sprites: @flag-cz @flag-de @flag-en @flag-es @flag-fr @flag-ru;
@spritesheet: 41px 38px '../img/sprite.png' @spritesheet-sprites;

/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}
*/
.sprite-width(@sprite) {
  width: extract(@sprite, 5);
}

.sprite-height(@sprite) {
  height: extract(@sprite, 6);
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 3);
  @sprite-offset-y: extract(@sprite, 4);
  background-position: @sprite-offset-x @sprite-offset-y;
}

.sprite-image(@sprite) {
  @sprite-image: extract(@sprite, 9);
  @sprite-image-bare: ~`"@{sprite-image}".slice(1, -1)`;
  background-image: url(@sprite-image-bare);
}

.sprite(@sprite) {
  .sprite-image(@sprite);
  .sprite-position(@sprite);
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.sprites(@sprites, @i: 1) when (@i <= length(@sprites)) {
  @sprite: extract(@sprites, @i);
  @sprite-name: extract(@sprite, 10);
  .@{sprite-name} {
    .sprite(@sprite);
  }
  .sprites(@sprites, @i + 1);
}
