@media print {
  *,
  *:before,
  *:after {
    width: 100% !important;
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    position: static !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 500px !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
  aside {
    display: none;
  }
  .sp-featuring, .sp-breadcrumb, #page #sp-contact .sp-contact-people .item img {
    display: none;
  }
  #main-header {
    display: none;
  }
  #main-footer {
    display: none;
  }
  #hp-visual {
    display: none;
  }
  #main-header .nav-menu, #page #hp-visual .hp-visual-inner, #page .hp-slides .hp-slides-inner .item .item-image{
    display: none;
  }
  #page .hp-slides .hp-slides-inner .item .item-content p {
    color: #000;
  }
  #page .hp-slides .hp-slides-inner .item .item-content {
  position: static;
  }
  #main-header .header .header-mobile-scroll, #hp-gallery {
    display: none;
  }
  #page .hp-offers .hp-offers-inner .item .item-title .item-heading {
    position: static;
  }
  #page .hp-offers .hp-offers-inner .item .item-text, #page .hp-offers .hp-offers-inner .item .item-title .item-image {
    display: none;
  }
  #page #sp-contact .sp-contact-inner .text-inner .icon-zvon-logo {
    display: none;
  }
  #contactForm, .icon-sign-1, .sub-footer  {
    display: none;
  }
  .heading-type-1 .heading, #page .hp-slides.center .hp-slides-inner .item .item-content p {
    text-align: left;
  }
  .slick-next, .slick-prev {
    display: none !important;
  }
  #footer {
    display: none;
  }
  .heading-sign-2 {
    display: none;
  }
}
